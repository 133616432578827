import React, { Component } from "react";
import logo from "../../images/logo.svg";
import { withRouter } from "react-router-dom";
import homeVideo from "../../images/video/bg.mp4";
import { connect } from "react-redux";
import loading from "../../images/loading.gif";
import facebook from "../../images/facebook.svg";
import twitter from "../../images/twitter.svg";
import sitelogo from "../../images/site-logo.png";
import instagram from "../../images/instagram.svg";
import "bootstrap/dist/js/bootstrap.bundle";
import UserActions from '../userActions';
import { Modal } from "react-bootstrap";
import closeIcon from "../../images/close.svg";
import letter from "../../images/letter.svg";
import whatsap from "../../images/whatsap.svg";
import tiktok from "../../images/TICKTOK.png";
import newwhatsapp from "../../images/WHATSAPP.png"
import dots from "../../images/dotss.png"
import search from "../../images/search-icon.png";
import searchgrey from "../../images/search-grey-icon.png";
import { getSiteContent, getPressFile, getIsModalOpen } from "../../redux/actions/actions";
import qrcode1 from '../../images/organize-app.png';
import qrcode2 from '../../images/booking-app.png';
import mainAppQRCode from "../../images/mainAppQRCode.png";
import organizerQRCode from "../../images/organizerQRCode.png";
import { QRCodeCanvas } from 'qrcode.react';
// import { WhatsAppWidget } from 'react-whatsapp-widget';
// import 'react-whatsapp-widget/dist/index.css';

class HomeHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showSignup: false,
            showSignin: false,
            showContact: false,
            unmute: false,
            navClass: false,
            email: '',
            password: '',
            username: '',
            menu: false,
            showProgress: true,
            showSearch: false,
            searchEvtName: ''
        };
    }

    componentDidMount() {
        this.getHomPageVideo();

        if (document.getElementById("scrollUp")) {
            if (document.documentElement.scrollTop <= 150)
                document.getElementById("scrollUp").style.display = "none";
            else
                document.getElementById("scrollUp").style.display = "";
        }

        window.addEventListener("scroll", this.handleScroll);

        setTimeout(this.getPressLink(), 5000);
    }

    getPressLink = async () => {
        const { getPressFile } = this.props;

        var pFile = new Object();
        pFile.title = 'PressFile';

        await getPressFile(pFile);
    }

    componentDidUpdate(prevProps) {
        const { isGetSiteContentSuccess: prevIsGetSiteContentSuccess } = prevProps;
        const { isGetSiteContentSuccess, siteContent } = this.props;

        if (prevIsGetSiteContentSuccess === false && isGetSiteContentSuccess) {
            this.setState({ showProgress: false });
            //console.log("home page: ", siteContent);
        }
    }

    getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        // Detect Android
        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // Other mobile OSes can be added here
        return 'unknown';
    };

    getHomPageVideo = async () => {
        let deviceType = this.getMobileOperatingSystem();
        const { getSiteContent } = this.props;
        var hpVideo = new Object();
        hpVideo.title = (deviceType === 'unknown') ? 'HomepageVideo' : 'HomepageVideoMobile';
        await getSiteContent(hpVideo);
    }

    handleScroll = () => {
        let header = document.getElementById("nav");
        let sticky = (header !== null) ? header.offsetTop : 0;

        if (window.pageYOffset > sticky) {
            this.setState({ navClass: true });
        } else {
            this.setState({ navClass: false });
        }

        if (document.getElementById("scrollUp") && document.documentElement.scrollTop <= 150)
            document.getElementById("scrollUp").style.display = "none";
        else if (document.getElementById("scrollUp"))
            document.getElementById("scrollUp").style.display = "";
    };

    unmuteVideo = (e) => {
        this.setState({ unmute: e });
    };

    handlecontactOpen = e => {
        this.setState({ showContact: true });
    };

    handlecontactClose = e => {
        this.setState({ showContact: false });
    };

    toggleMenu = () => {
        const { getIsModalOpen } = this.props;
        if(!this.state.menu && window.location.pathname.split('/')[1] === 'tickets'){
            // localStorage.setItem("isModalOpen", true);
            getIsModalOpen(true);
        }else{
            getIsModalOpen(false);
            // localStorage.setItem("isModalOpen", false);
        }
        this.setState({ menu: !this.state.menu })
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    handleSearchOpen = e => {

        this.setState({ showSearch: true }, function () {
            document.getElementById("searchEvtName").focus();
        });
    };

    handleSearchClose = e => {
        this.setState({ showSearch: false });
    };

    handleEnter = (e) => {
        if (e.key === "Enter")
            this.handleSearch();
    };

    handleSearch = () => {
        const { searchEvtName } = this.state;

        this.setState({ show: false, showSearch: false });

        if (window.location.pathname === "/events")
            window.location.reload();

        this.props.history.push({
            pathname: "/events",
            state: {
                eName: searchEvtName
            },
        });
    }

    render() {
        const { unmute, showContact, navClass, showProgress, showSearch, searchEvtName } = this.state;
        const show = (this.state.menu) ? "show" : "";
        const { siteContent, pressFile } = this.props;

        return (
            <>
                <div className="header header__homepage">
                    {showProgress ? <div style={{ justifyContent: "center", display: "flex" }}><img src={loading} style={{ width: "32px", height: "32px", alignitems: "center" }} /></div> :
                        <video autoPlay muted={unmute ? false : true} loop id="videoBG" class="header__bg" playsInline>
                            <source src={siteContent} type="video/mp4" />
                        </video>
                    }
                    <button id="videoMuted" type="button" class="video-muted" onClick={() => this.unmuteVideo(!unmute)}>
                        <i class={unmute ? "fas fa-volume-up" : "fas fa-volume-mute"}></i>
                    </button>
                    <nav style={{ padding: "20px 15px" }} class={navClass ? "navbar navbar-expand-xl navbar-dark sticky sticky-bg " : "navbar navbar-expand-xl navbar-dark sticky"} id="nav">
                        <a class="navbar-brand" href="/">
                            <img src={sitelogo} alt="SCANK" class="navbar__nav-logo" />
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleMenu}>
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class={"collapse navbar-collapse hight " + show} id="navbar">
                            <ul class="navbar-nav mr-auto ulalign">
                                <li class="nav-item d-flex align-items-center">
                                    <a class="nav-link-header clrtxtres " href="/">
                                        Home
                                    </a>
                                </li>
                                <li class="nav-item d-flex align-items-center">
                                    <a class="nav-link-header clrtxtres" href="/events">
                                        Events
                                    </a>
                                </li>
                                {/* <li class="nav-item d-flex align-items-center">
                                    <a class="nav-link-header clrtxtres" href="/about">
                                        About
                                    </a>
                                </li> */}
                                <li class="nav-item d-flex align-items-center">
                                    <a class="nav-link-header clrtxtres" onClick={this.handlecontactOpen} data-toggle="modal">
                                        Contact
                                    </a>
                                </li>
                                <li class="nav-item d-flex align-items-center">
                                    <a class="nav-link-header clrtxtres" onClick={this.handleSearchOpen} data-toggle="modal">
                                        <img class={navClass ? "searchMenuImg" : "searchMenuImg_white"} width="20" height="20" />&nbsp;<span class={navClass ? "serachMenu" : "serachMenu_white"}>Find an event</span>
                                    </a>
                                </li>
                                <li class="nav-item d-flex align-items-center">
                                    <a href="/request-code" class="header__events-btn header__request-btn_code" aria-label="Request Code">
                                        Request invite code
                                    </a>
                                </li>
                            </ul>
                            <UserActions />

                        </div>
                    </nav>
                    <ul class="header__social list">
                        <li class="header__social-item">
                            <a href="https://www.tiktok.com/@scankworld" class="header__social-link" aria-label="Go to facebook" target="_blank">
                                <img src={tiktok} class="header__social-icon" width="13" height="13" alt="Facebook" />
                            </a>
                        </li>
                        <li class="header__social-item">
                            <a href="https://www.instagram.com/scankworld/" class="header__social-link" aria-label="Go to instagram" target="_blank">
                                <img src={instagram} class="header__social-icon" width="11" height="13" alt="isntagram" />
                            </a>
                        </li>
                        <li class="header__social-item">
                            <a href="https://twitter.com/scankworld" class="header__social-link" aria-label="Go to twitter" target="_blank">
                                <img src={twitter} class="header__social-icon" width="13" height="13" alt="Twitter" />
                            </a>
                        </li>
                        {/* <li class="header__social-item">
                            <a href="https://www.facebook.com/Scank-102863231703024" class="header__social-link" aria-label="Go to facebook" target="_blank">
                                <img src={facebook} class="header__social-icon" width="13" height="13" alt="Facebook" />
                            </a>
                        </li> */}

                        <li class="header__social-item header__social-item_text">
                            Follows Us
                        </li>
                    </ul>
                    <span class="video-scroll">
                        <a href="#">
                            <span class="mouse">
                                <span></span>
                            </span>
                        </a>
                    </span>
                </div>
                <Modal show={showContact} onHide={this.handlecontactClose} className="login" dialogClassName="modal-dialog-centered">
                    <Modal.Body>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ textAlign: "right", margin: "10px", top: "8px" }}>
                            <img src={closeIcon} width="30" height="30" alt="Close icon" onClick={() => this.handlecontactClose()} />
                        </button>

                        <div className="modal-dialog contacts__modal-dialog" style={{ paddingTop: "20px" }} >
                            <div className="modal-content-body contacts__modal-content" style={{ overflow: "inherit" }}>
                                <div className="contacts__container" data-keyboard="false">

                                    <div className="contacts__logo-wrapper">
                                        <img
                                            src={sitelogo}
                                            alt="Contacts Logotype"
                                            className="contacts__logo-image"
                                        />
                                    </div>
                                    <div className="contacts__content-box">
                                        <div className="contacts__touch-wrapper">
                                            {/* <h4 className="contacts__touch-title">Get in touch</h4>
                                                <p className="contacts__touch-description">
                                                    For any queries just give us a shout!
                                                </p> */}
                                            {/* <a href="#" className="contacts__touch-link">
                                                    <img
                                                        src={letter}
                                                        width={34}
                                                        height={12}
                                                        alt="icon"
                                                        className="touch__link-icon"
                                                    />
                                                    Info@scank.co.uk
                                                </a> */}
                                            <a
                                                href="https://wa.me/+447379296347" target="_blank"
                                                className="contacts__touch-tel"
                                                aria-label="phone number"
                                            >
                                                <div style={{ width: "200px" }}>
                                                    <img
                                                        src={newwhatsapp}


                                                        alt="icon"
                                                        className="contacts__tel-icon"
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "-20px" }}>
                                                    <h3 class="h3whatsapp">What's up? Let's talk</h3>
                                                    <p class="pwhatsapp">Click to connect directly to our Whatsapp</p>
                                                    <img
                                                        src={dots}
                                                        width={"60%"}
                                                        alt="icon"
                                                    />
                                                </div>
                                            </a>
                                            <div className="links-section">
                                                <div>
                                                    <a href="mailto:hello@scank.co.uk" className="contacts__touch-link emaillink">
                                                        <img
                                                            src={letter}
                                                            width={34}
                                                            height={12}
                                                            alt="icon"
                                                            className="touch__link-icon"
                                                        />
                                                    Hello@scank.co.uk
                                                    </a>
                                                </div>
                                                <div>
                                                    {/* <div class="whtsapp">
                                                        <WhatsAppWidget companyName="Scank" replyTimeText="hello"  phoneNumber="+447932462118" />
                                                        </div> */}
                                                    <ul className="contacts__touch-list list" style={{ paddingLeft: "14px" }}>
                                                        <li className="contacts__list-item">
                                                            <a href="/terms" className="contacts__list-link">
                                                                Terms
                                                            </a>
                                                        </li>
                                                        <li className="contacts__list-item">
                                                            <a href="/privacy" className="contacts__list-link">
                                                                Privacy Policy
                                                            </a>
                                                        </li>
                                                        <li className="contacts__list-item">
                                                            <a href={pressFile} className="contacts__list-link">
                                                                Press
                                                            </a>
                                                        </li>
                                                        <li className="contacts__list-item">
                                                            <a href="/faq" className="contacts__list-link">
                                                                Faqs
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contacts__follow-wrapper">
                                            <h4 className="contacts__follow-title">Get The App</h4>
                                            <p class="contacts__follow-description" style={{ marginBottom: "20px" }}>
                                                Download the Scank App today for The Ultimate Experience!
                                            </p>
                                            <div class="qrcodeflx">
                                                <div style={{ marginRight: "20px" }}>
                                                {this.getMobileOperatingSystem() === "unknown" ?<div style={{ marginTop: "20%", width: "150px" }}> <QRCodeCanvas value={`${window.location.origin}/redirect/organizer`}  size={150} /></div>:   <img width={90} src={qrcode1}
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevents the default action
                                                            const os = this.getMobileOperatingSystem();
                                                            if (os === "iOS") {
                                                                window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                                                            } else if (os === "Android") {
                                                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                                                            } else {
                                                                console.log("Unsupported OS or unknown")
                                                            }
                                                            // For other cases, do nothing
                                                        }}></img>}
                                                    <p style={{ fontSize: "small" }}  onClick={(e) => {
                                                            e.preventDefault(); // Prevents the default action
                                                            const os = this.getMobileOperatingSystem();
                                                            if (os === "iOS") {
                                                                window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                                                            } else if (os === "Android") {
                                                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                                                            } else {
                                                                console.log("Unsupported OS or unknown")
                                                            }
                                                            // For other cases, do nothing
                                                        }} className="mt-2">Organise events!</p>
                                                </div>
                                                <div style={{ marginLeft: "20px" }}>
                                                {this.getMobileOperatingSystem() === "unknown" ?<div style={{ marginTop: "20%", width: "150px" }}> <QRCodeCanvas value={`${window.location.origin}/redirect/main`} fgColor="#ff0000" size={150}  /> </div>:  <img width={90} src={qrcode2}
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevents the default action
                                                            const os = this.getMobileOperatingSystem();
                                                            if (os === "iOS") {
                                                                window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                                                            } else if (os === "Android") {
                                                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                                                            } else {
                                                                console.log("Unsupported OS or unknown")
                                                            }
                                                            // For other cases, do nothing
                                                        }} ></img>}
                                                    <p style={{ fontSize: "small" }}  onClick={(e) => {
                                                            e.preventDefault(); // Prevents the default action
                                                            const os = this.getMobileOperatingSystem();
                                                            if (os === "iOS") {
                                                                window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                                                            } else if (os === "Android") {
                                                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                                                            } else {
                                                                console.log("Unsupported OS or unknown")
                                                            }
                                                            // For other cases, do nothing
                                                        }}  className="mt-2">Grab Tickets!</p>
                                                </div>
                                            </div>
                                            <ul className="contacts__follow-social list">
                                                <li className="contacts__social-item">
                                                    <a href="https://www.facebook.com/Scank-102863231703024" target="_blank" className="contacts__social-link">
                                                        <img
                                                            src={facebook}
                                                            width={14}
                                                            height={18}
                                                            alt="Facebook icon"
                                                            className="contacts__social-icon"
                                                            aria-label="Facebook"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="contacts__social-item">
                                                    <a href="https://twitter.com/scankworld" className="contacts__social-link" target="_blank">
                                                        <img
                                                            src={twitter}
                                                            width={20}
                                                            height={13}
                                                            alt="Twitter icon"
                                                            className="contacts__social-icon"
                                                            aria-label="Twitter"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="contacts__social-item">
                                                    <a href="https://www.instagram.com/scankworld/" className="contacts__social-link" target="_blank">
                                                        <img
                                                            src={instagram}
                                                            width={16}
                                                            height={16}
                                                            alt="Instagram icon"
                                                            className="contacts__social-icon"
                                                            aria-label="Instagram"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="contacts__social-item">
                                                    <a href="https://www.tiktok.com/@scankworld" className="contacts__social-link" target="_blank">
                                                        <img
                                                            src={tiktok}
                                                            width={16}
                                                            height={16}
                                                            alt="Instagram icon"
                                                            className="contacts__social-icon"
                                                            aria-label="Instagram"
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Modal.Body>
                </Modal>


                <Modal show={showSearch} onHide={this.handleSearchClose} className="contact_modal"  >
                    <Modal.Body>

                        <div id="contacts" tabIndex={-1} aria-hidden="true" style={{ height: "100%" }}>
                            <div className="modal-dialog contacts__modal-dialog" style={{pointerEvents:"all"}} >
                                <div className="modal-content-body contacts__modal-content">
                                    <button type="button" class="close modal__close-btn" data-dismiss="modal" aria-label="Close" onClick={() => this.handleSearchClose()}>
                                        <span aria-hidden="true">
                                            <img src={closeIcon} width="22" height="22" alt="Close icon" class="modal__close-icon" />
                                        </span>
                                    </button>
                                    <div className="contacts__container" data-keyboard="false">
                                        <div className="contacts__content-box" style={{ display: "block", textAlign: "center" }}>
                                            <div className="contacts__touch-wrapper" style={{ fontSize: "17px", fontWeight: "600", marginBottom: "5px", color: "#353535" }}>
                                                THE ULTIMATE EXPERIENCE
                                            </div>
                                            <div className="contacts__touch-wrapper" style={{ display: "contents" }}>
                                                <input type="text" placeholder="Search an event..." name="search" style={{ textAlign: "center", border: "1px solid #353535", borderRadius: "20px", width: "350px", height: "40px" }} onKeyDown={(e) => {
                                                    this.handleEnter(e);
                                                }} id="searchEvtName" className="searchEvtName" value={searchEvtName} onChange={this.handleChange} onBlur={this.handleSearchClose} />
                                                <button type="submit" style={{ display: "none", background: "#e5e5e5", float: "left", border: "none" }} onClick={this.handleSearch}><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        isGetSiteContentSuccess: state.siteContentReducer.isGetSiteContentSuccess,
        isGetSiteContentError: state.siteContentReducer.isGetSiteContentError,
        siteContent: state.siteContentReducer.siteContent,
        isGetPressFileSuccess: state.siteContentReducer.isGetPressFileSuccess,
        isGetPressFileError: state.siteContentReducer.isGetPressFileError,
        pressFile: state.siteContentReducer.pressFile,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getSiteContent: (data) => dispatch(getSiteContent(data)),
        getPressFile: (data) => dispatch(getPressFile(data)),
        getIsModalOpen: (data) => dispatch(getIsModalOpen(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeHeader));