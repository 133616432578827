import React, { Component } from 'react';
import MobileLoaderVideo from "../../images/video/mobileLoader.mp4";
import WebLoaderVideo from "../../images/video/webLoader.mp4";
class Preloader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        const hasVisitedRequestCode = localStorage.getItem("hasVisitedRequestCode");
        this.loadingTimeout = setTimeout(() => {
            if(!hasVisitedRequestCode){
                window.location.pathname = "/request-code";
                // this.props.history.push("/request-code");
            }
            this.setState({ isLoading: false });
        }, 5000);
    }

    componentWillUnmount() {
        clearTimeout(this.loadingTimeout);
    }

    render() {
        const { isLoading } = this.state;
        const isMobile = window.matchMedia('(max-width: 600px)').matches;

        return (
            <div>
                {isLoading && (
                    <div className="preloader">
                        <video
                            src={isMobile ? MobileLoaderVideo || "../../images/video/mobileLoader.mp4" : WebLoaderVideo || "../../images/video/webLoader.mp4"}
                            autoPlay
                            muted
                            loop
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </div>
                )}
                {!isLoading && this.props.children}
            </div>
        );
    }
}

export default Preloader;
